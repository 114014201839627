import {
	Avatar,
	Box,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	StepConnector,
	Typography,
	useTheme,
} from '@mui/material';

import TAGLink from '@/components/TAGLink';
import { Card as TCard } from '@/types/generated';
import AspectRatioPaddingBox from '@/components/AspectRatioPaddingBox';
import ImageWrapper from '@/components/ImageWrapper';
import RichText, { RichTextContent } from '@/components/RichText';
import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';
import TermsAndConditions from '@/components/TermsAndConditions';
import getHeadingVariant from '@/utils/resolveContentfulHeadingVariant';
import { HeadingTag } from '@/types';
import { textToJumpLink } from '@/utils';
import ContentfulButton from '@/components/ContentfulButton';
import ImageAsset from '@/components/ImageAsset';
import { IImage } from '@/components/ImageAsset/ImageAsset';
import ResourceCardTile from '@/components/MultiCard/ResourceCardTile';

import {
	getCardStyles,
	getCardContentStyles,
	getCardActionStyles,
	pdfLinkStyles,
	getAvatarStyles,
	getStepperCardBoxStyles,
} from './CardItem.styles';

interface ICardItem {
	card: TCard;
	isSmallScreen: boolean;
	isDarkBackground?: boolean;
	displayAsStepper?: boolean;
	showLeftConnector?: boolean;
	showRightConnector?: boolean;
	enableResourceCardMode?: boolean;
}

export default function CardItem({
	card,
	isDarkBackground,
	isSmallScreen,
	displayAsStepper = false,
	showLeftConnector,
	showRightConnector,
	enableResourceCardMode = false,
}: ICardItem) {
	const isDarkWrapperBackground = isDarkBackground || false;
	const theme = useTheme();
	const hasImageOrIcon = Boolean(card.cardIcon || card.imageAsset);
	const cardButtons = card?.buttonsCollection?.items;
	const iconColor = isDarkWrapperBackground ? 'neutrals.white' : 'tertiary.main';

	// TODO: refactor CardItem when time permitting to breakout card components and use helpers to determine what to render depending on Card mode
	if (card.imageAsset && enableResourceCardMode) {
		return <ResourceCardTile card={card} isSmallScreen={isSmallScreen} />;
	}

	return (
		<Card
			data-test-id={`group_card_${card.sys.id}`}
			id={textToJumpLink(card.title)}
			sx={getCardStyles({
				backgroundColor: card.backgroundColor,
				isDarkWrapperBackground,
				displayAsStepper,
				isSmallScreen,
			})}
		>
			{displayAsStepper ? (
				<Box
					sx={getStepperCardBoxStyles(isSmallScreen)}
					data-test-id={`multicard_as_stepper_step_item_${card.sys.id}`}
				>
					{!isSmallScreen && <StepConnector sx={{ visibility: showLeftConnector ? 'visible' : 'hidden' }} />}
					{/* {showLeftConnector && !isSmallScreen && <StepConnector />} */}
					<Avatar sx={getAvatarStyles(theme, hasImageOrIcon)}>
						{card.imageAsset ? (
							<ImageAsset
								desktopImage={card.imageAsset.desktopImage as IImage}
								mobileImage={card.imageAsset.mobileImage as IImage}
								isBackgroundImage={true}
								dataTestId={`multicard_as_stepper_avatar_image_asset_${card.sys.id}`}
							/>
						) : card.cardIcon ? (
							<TAGSvgIcon
								width="60"
								height="60"
								icon={card.cardIcon as IconName}
								htmlColor={iconColor}
								dataTestId={`multicard_as_stepper_avatar_icon_${card.sys.id}`}
							/>
						) : null}
					</Avatar>
					{/* {showRightConnector && <StepConnector />} */}
					<StepConnector sx={{ visibility: showRightConnector ? 'visible' : 'hidden' }} />
				</Box>
			) : null}

			{!displayAsStepper && card.imageAsset && (
				<AspectRatioPaddingBox
					aspectRatio="5:4"
					contentfulAspectRatio={card.imageRatio}
					paddingBoxSx={{ height: 'auto' }}
				>
					<CardMedia
						component={card.imageAsset && card.buttonUrl ? 'a' : 'div'}
						href={card.buttonUrl || undefined}
						data-test-id={`group_card_image_asset_link_${card.sys.id}`}
					>
						<ImageAsset
							desktopImage={card.imageAsset.desktopImage as IImage}
							mobileImage={card.imageAsset.mobileImage as IImage}
							isBackgroundImage={false}
							dataTestId={`img_asset_card_item_background_${card.sys.id}`}
						/>
					</CardMedia>
				</AspectRatioPaddingBox>
			)}

			{/* To Do : remove this once transition to brandfolder imageAssets is in place  */}

			{!displayAsStepper && !card.imageAsset && card.image?.url && (
				<CardMedia
					component={card.imageUrl ? 'a' : 'div'}
					{...(card.imageUrl ? { href: card.imageUrl } : {})}
					data-test-id={`group_card_image_link_${card.sys.id}`}
				>
					<AspectRatioPaddingBox aspectRatio="5:4" contentfulAspectRatio={card.imageRatio}>
						<ImageWrapper
							maxWidth="100%"
							minWidth="100%"
							background-size="cover"
							background-repeat="no-repeat"
							background-position="center center"
							object-fit="cover"
							src={card.imageUrl || card.image?.url}
							alt={card.image?.description ?? ''}
						/>
					</AspectRatioPaddingBox>
				</CardMedia>
			)}

			<CardContent sx={getCardContentStyles(isSmallScreen, displayAsStepper, card.cardTextAlignment)}>
				{card.cardIcon && !displayAsStepper && (
					<Grid
						item
						sx={{
							display: 'flex',
							justifyContent: card.cardIconAlignment,
							width: '100%',
						}}
					>
						<TAGSvgIcon
							size={64}
							htmlColor={iconColor}
							icon={card.cardIcon as IconName}
							dataTestId={`card_icon_${card.cardIcon}`}
						/>
					</Grid>
				)}
				{card.eyebrow && (
					<Typography
						lineHeight="1rem"
						fontSize="0.875rem"
						color={
							isDarkBackground && card.backgroundColor === 'transparent' ? 'text.light' : 'text.secondary'
						}
						variant={isSmallScreen ? 'bodySmallBook' : 'bodyMediumBook'}
						data-test-id={`text_card_eyebrow_${card.sys.id}`}
					>
						{card.eyebrow}
					</Typography>
				)}
				{card.title && (
					<Typography
						variant={getHeadingVariant(card.titleHtag as HeadingTag)}
						color={
							isDarkBackground && card.backgroundColor === 'transparent' ? 'text.light' : 'text.primary'
						}
						data-test-id={`text_card_title_${card.sys.id}`}
					>
						{card.title}
					</Typography>
				)}
				{card.description && (
					<Typography
						color={
							isDarkBackground && card.backgroundColor === 'transparent' ? 'text.light' : 'text.secondary'
						}
						variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'}
						data-test-id={`text_card_description_${card.sys.id}`}
						textAlign={
							displayAsStepper && isSmallScreen
								? 'left'
								: card.cardTextAlignment === 'center'
								? 'center'
								: 'left'
						}
					>
						{card.description}
					</Typography>
				)}
				{card.richTextDescription && (
					<RichText
						content={card.richTextDescription.json as RichTextContent}
						links={card.richTextDescription.links}
						docProps={{
							textAlign:
								displayAsStepper && isSmallScreen
									? 'left'
									: card.cardTextAlignment === 'center'
									? 'center'
									: 'left',
						}}
					/>
				)}
			</CardContent>

			{/* button collection should be supported in future uses */}
			{cardButtons && cardButtons.length > 0
				? cardButtons.map((button) =>
						button ? (
							<CardActions
								key={button?.sys?.id}
								sx={getCardActionStyles({
									isSmallScreen,
									cardActionAlignment: card.cardTextAlignment,
									displayAsStepper,
								})}
							>
								<ContentfulButton
									{...button}
									defaultVariant="secondaryDefault"
									dataTestId={`group_card_button_${button?.name || ''}`}
								/>
							</CardActions>
						) : null
				  )
				: null}

			{/* TODO: deprecate button and use button collections above while we temporarily support buttonUrl */}
			{card.buttonUrl && (
				<CardActions
					sx={getCardActionStyles({
						isSmallScreen,
						cardActionAlignment: card.cardTextAlignment,
						displayAsStepper,
					})}
				>
					<ContentfulButton
						_id={card.sys.id}
						sys={card.sys}
						buttonLink={card.buttonUrl}
						label={card.buttonText}
						defaultVariant="secondaryDefault"
						variant={card.buttonVariant}
						icon={card.buttonIcon}
						iconPosition={card.buttonIconPosition}
						iconSize={card.buttonIconSize}
						size={card.buttonSize || 'medium'}
						dataTestId="button_card_action"
						contentfulMetadata={card.contentfulMetadata}
						analyticsContext={card.analyticsContext}
					/>
				</CardActions>
			)}

			{card.pdfFileLink?.url && (
				<TAGLink href={card.pdfFileLink.url} openInNewTab linkSx={pdfLinkStyles}>
					<Typography color="text.interactive" variant="bodyMediumBold" title="Download">
						{card.pdfDownloadTitle || 'Download'}
					</Typography>
				</TAGLink>
			)}

			{card.tc && (
				<Box
					position="relative"
					paddingX={isSmallScreen ? '1rem' : 0}
					data-test-id={`group_card_terms_and_conditions_wrapper_${card.sys.id}`}
				>
					<TermsAndConditions {...card.tc} textSx={{ textAlign: card.cardTextAlignment || 'left' }} />
				</Box>
			)}
		</Card>
	);
}
